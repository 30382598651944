import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { pdfjs, Document, Page } from 'react-pdf';

// Styles
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// Components
import { Line, LineChart } from 'recharts';
import { Loader } from '../../../../Components/Shared/Loader';

// Redux
import requestFactory from '../../../../services/request.factory';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js',
	import.meta.url
).toString();

export const createCell = {
	integer: ({ classes, cellValue, multiValue }) => {
		const { rowCell } = classes;

		if (multiValue)
			return (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<LineChart width={100} height={50} data={cellValue}>
						<Line
							dataKey={(v) => v}
							stroke="#8884d8"
							strokeWidth={1}
							dot={false}
						/>
					</LineChart>
				</div>
			);

		return <Typography className={rowCell}>{parseInt(cellValue)}</Typography>;
	},
	boolean: ({ classes, cellValue }) => {
		const { rowCell } = classes;
		return (
			<Typography className={rowCell}>
				{cellValue === '' || cellValue === 'false' ? 'False' : 'True'}
			</Typography>
		);
	},
	datetime: ({ classes, cellValue }) => {
		const { rowCell } = classes;

		return <Typography className={rowCell}>{cellValue}</Typography>;
	},
	float: ({ classes, cellValue, multiValue }) => {
		const { rowCell } = classes;
		if (multiValue && cellValue.length > 1)
			return (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<LineChart width={100} height={50} data={cellValue}>
						<Line
							dataKey={(v) => v}
							stroke="#8884d8"
							strokeWidth={1}
							dot={false}
						/>
					</LineChart>
				</div>
			);
		return parseFloat(cellValue) || 0.0;
	},
	text: ({ classes, cellValue }) => {
		const { rowCell } = classes;

		return <Typography className={rowCell}>{cellValue}</Typography>;
	},
	category: ({ classes, cellValue, categoriesState, valueType, params }) => {
		const { rowCell } = classes;

		if (!cellValue) return <Typography> </Typography>;

		if (
			categoriesState &&
			categoriesState[valueType] &&
			categoriesState[valueType].length > 0
		) {
			const tmpCats = categoriesState[valueType].find(
				(cat) => cat.name === params.field
			)?.categories;
			const tmpValue = tmpCats.find(
				(cat) => cat.display_name === cellValue || cat.name === cellValue
			);

			if (tmpValue && tmpValue.display_name)
				return (
					<Typography className={rowCell}>{tmpValue.display_name}</Typography>
				);
			if (tmpValue && tmpValue.name)
				return (
					<Typography className={rowCell}>
						{tmpValue.display_name || tmpValue.name}
					</Typography>
				);
		}
		return <Typography className={rowCell}>{cellValue}</Typography>;
	},
	generic_file: ({ classes }) => {
		const { rowCell } = classes;

		return <Typography className={rowCell}>GenericFile</Typography>;
	},
	document_file: ({ cellValue, documentsBufferState }) => {
		if (
			documentsBufferState &&
			documentsBufferState.length > 0 &&
			documentsBufferState.find((element) => element.elementId === cellValue)
		) {
			return (
				<Box
					sx={{
						mt: '20px',
						mb: '20px',
						display: 'flex',
						backgroundColor: 'white',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Document
						file={
							documentsBufferState.find(
								(element) => element.elementId === cellValue
							).url
						}
					>
						<Page
							renderMode="svg"
							renderTextLayer={false}
							renderAnnotationLayer={false}
							pageNumber={1}
						/>
					</Document>
				</Box>
			);
		}
		if (cellValue === undefined) {
			return '';
		}
		return <Loader size="S" />;
	},
	image_file: ({ cellValue, cellName, imagesBufferState }) => {
		if (
			imagesBufferState &&
			imagesBufferState.length > 0 &&
			imagesBufferState.find((element) => element.elementId === cellValue)
		)
			return (
				<img
					src={
						imagesBufferState.find((element) => element.elementId === cellValue)
							.thumbnail
					}
					alt={cellName}
					style={{ width: '100%' }}
				/>
			);
		if (cellValue === undefined) {
			return '';
		}
		return <Loader size="S" />;
	},
	video_file: ({ classes }) => {
		const { rowCell } = classes;

		return <Typography className={rowCell}>VideoFile</Typography>;
	},
	audio_file: ({ classes }) => {
		const { rowCell } = classes;

		return <Typography className={rowCell}>AudioFile</Typography>;
	},
	shape: ({ classes }) => {
		const { rowCell } = classes;

		return <Typography className={rowCell}>Shape</Typography>;
	},
	slice: ({ classes }) => {
		const { rowCell } = classes;

		return <Typography className={rowCell}>Slice</Typography>;
	},
};
